/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import LargeQuote from "../../src/components/LargeQuote";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    strong: "strong",
    h2: "h2",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Why product management is harder than tech"), "\n", React.createElement(_components.p, null, "On the surface it might look as if product management is just about writing a couple\nof Jira tickets and have them implemented by engineers. And since the daily activities\nare typically not very technical, ", React.createElement(_components.strong, null, "people might be inclined to think that anyone can do product management"), "."), "\n", React.createElement(_components.p, null, "At least that is how it might look from an engineer's perspective.\nWell, unfortunately things are not that simple."), "\n", React.createElement(LargeQuote, {
    text: "Anyone can do product management. But only few people can do product mangement really well."
  }), "\n", React.createElement(_components.h2, null, "To the customer, only the product matters"), "\n", React.createElement(LargeQuote, {
    text: "Customers have problems and they want solutions. They couldn't care less about\nthe underlying technology."
  }), "\n", React.createElement(_components.p, null, "Customers only care whether the product you are offering is ", React.createElement(_components.strong, null, "solving their problems"), "."), "\n", React.createElement(LargeQuote, {
    text: "Software is not an end in itself but it is a means to create business value."
  }), "\n", React.createElement(_components.p, null, "So basically\nby offering software to the customer, you give the customer superpower. But it was never about\nthe software. ", React.createElement(_components.strong, null, "It was always about the the most effective way to solve customer's problem"), ". In other words: customers\ndon't care about tech, they care about solutions."), "\n", React.createElement(_components.p, null, "Therefore, product has a super important role. Because after all product\nmanagement decides what features and functionalities will be built next. All decisions are made with the\ncustomer in mind: ", React.createElement(_components.strong, null, "which feature brings the most (business) value for the customer?")), "\n", React.createElement(_components.p, null, "While this sounds very straight-forward on an abstract level, this actually becomes\nmuch harder in practise."), "\n", React.createElement(_components.h3, null, "Product Management: many options, but no time to implement"), "\n", React.createElement(LargeQuote, {
    text: "When you do product management you got plenty of ideas and the dragging\nfeet of the tech department."
  }), "\n", React.createElement(_components.p, null, "There is just no time to implement everything. So you need to prioritize. Now if\neveryone was perfectly rational, the prioritization would be pretty easy. However,\n", React.createElement(_components.strong, null, "stakeholders oftentimes don't optimize for the global, company-wide optimum, but\nfor their own interests"), "."), "\n", React.createElement(_components.p, null, "So if you do product, you need to assess in a neutral way what functionality to\nimplement next. It can be really hard to distinguish the things that are truly\nworth doing from just some stakeholder's personal interest."), "\n", React.createElement(_components.h3, null, "Product Managment and requirements engineering"), "\n", React.createElement(_components.p, null, "Anyone can write a ticket. Few people can write really good tickets."), "\n", React.createElement(LargeQuote, {
    text: "Creating tickets might seem like an easy, sometimes even dull task. But actually, if\ntaken seriously, it is an engineering discipline which is called requirements engineering."
  }), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, "engineering"), " part here is important because ultimately we strive for a ", React.createElement(_components.strong, null, "systematic,\nrepeatable and engineering-driven way of eliciting requirements"), "."), "\n", React.createElement(_components.p, null, "As a product manager it is important to understand the level of detail an engineer needs to actually implement\na ticket. And as a product manager, you also need to take care of all the edge cases:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "What happens if the customer enters wrong data?"), "\n", React.createElement(_components.li, null, "What happens if a particular data point is missing?"), "\n", React.createElement(_components.li, null, "..."), "\n"), "\n", React.createElement(_components.p, null, "In my experience there's only few product manager who understand\nthe level of detail that a developer needs to actually implement the ticket."), "\n", React.createElement(_components.h3, null, "Tech and product have its own challenges"), "\n", React.createElement(_components.p, null, "Granted, software and technology does have its learning curve."), "\n", React.createElement(LargeQuote, {
    text: "Spotting a bug\nin a big and messy code base can be challenging. But the thing is that the entire\nsoftware development process in itself is very logical and consistent."
  }), "\n", React.createElement(_components.p, null, "Even though code bases might be hard to understand at first, once you get the\nhang on it ", React.createElement(_components.strong, null, "implementing a new feature is a very structured and repeatable process"), " - unless you do some cutting-edge stuff."), "\n", React.createElement(LargeQuote, {
    text: "So in other words, the implementation of a ticket is typically\na very straight forward and linear process without many complications once you understand the code base."
  }), "\n", React.createElement(_components.p, null, "However, things look completely different when we are talking about the product side.\nOn the product side you need to"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "handle many stakeholders at the same time who don't (want to) see the bigger picture"), "\n", React.createElement(_components.li, null, "deal with ", React.createElement(_components.strong, null, "politics"), " and ", React.createElement(_components.strong, null, "irrational people")), "\n", React.createElement(_components.li, null, "involve a lot of different people - even for tiny changes"), "\n", React.createElement(_components.li, null, "write a complete, unambiguous and contradiction-free tickets that also\ntake into account the edge cases (if you have software development experience then that helps a lot)"), "\n"), "\n", React.createElement(LargeQuote, {
    text: "The complexity in product is not due to its analytical nature,\nbut because if involves dealing with people who oftentimes behave in completely irrational\nway."
  }), "\n", React.createElement(_components.p, null, "As a product manager you are always in between stakeholders and\noftentimes it is the ", React.createElement(_components.strong, null, "missing, incomplete or incorrect requirements"), " that cause delays in\nprojects and not the coding speed."), "\n", React.createElement(_components.h2, null, "Conclusion"), "\n", React.createElement(_components.p, null, "As we have seen, product is a ", React.createElement(_components.strong, null, "multi-dimensional discipline"), " that requires the product\nmanager to align with a lot of different people who might ", React.createElement(_components.strong, null, "behave in an irrational way"), ".\nIn a way product management is less demanding on the analytical side - even though it\ntakes analytical thinking to provide a clear, contradiction-free and complete ticket."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
