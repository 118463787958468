import React from "react";
import Quote from "./Quote";

const LargeQuote = (props) => {
  return (
    <Quote>
      <p className="text-gray-800 my-2 text-lg">{props.text}</p>
    </Quote>
  );
};



export default LargeQuote;
