import React from "react";
import { FaQuoteLeft } from "react-icons/fa";

const Quote = (props) => {
  return (
    <div className="flex items-top my-8">
      <FaQuoteLeft className="text-2xl text-blue-800 mr-8 flex-shrink-0" />
      {props.children}
    </div>
  );
};

export default Quote;
